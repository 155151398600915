// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArAboutNewsLinks-module--ar-h1-mobile--shpBq";
export var arH2Mobile = "ArAboutNewsLinks-module--ar-h2-mobile--2meIf";
export var arH3Mobile = "ArAboutNewsLinks-module--ar-h3-mobile--1dwt0";
export var arH4Mobile = "ArAboutNewsLinks-module--ar-h4-mobile--3FW1A";
export var arH5Mobile = "ArAboutNewsLinks-module--ar-h5-mobile--OTdVk";
export var arH1 = "ArAboutNewsLinks-module--ar-h1--1l_DO";
export var arH2 = "ArAboutNewsLinks-module--ar-h2--M-tJB";
export var arH3 = "ArAboutNewsLinks-module--ar-h3--2lVRx";
export var arH4 = "ArAboutNewsLinks-module--ar-h4--3O0Nw";
export var arH5 = "ArAboutNewsLinks-module--ar-h5--22Qgd";
export var arParagraphXl = "ArAboutNewsLinks-module--ar-paragraph-xl--3Wnyi";
export var arParagraphXlMedium = "ArAboutNewsLinks-module--ar-paragraph-xl-medium--2eSip";
export var arParagraphBase = "ArAboutNewsLinks-module--ar-paragraph-base--39m73";
export var arParagraphBaseMedium = "ArAboutNewsLinks-module--ar-paragraph-base-medium--33jWG";
export var arParagraphSmall = "ArAboutNewsLinks-module--ar-paragraph-small--33eoL";
export var arParagraphSmallMedium = "ArAboutNewsLinks-module--ar-paragraph-small-medium--210aK";
export var arParagraphXsmall = "ArAboutNewsLinks-module--ar-paragraph-xsmall--3RZ3p";
export var arParagraphXsmallMedium = "ArAboutNewsLinks-module--ar-paragraph-xsmall-medium--dibgB";
export var arLinkMobile = "ArAboutNewsLinks-module--ar-link-mobile--3fECJ";
export var arLinkDesktop = "ArAboutNewsLinks-module--ar-link-desktop--2G2WP";
export var arQuote = "ArAboutNewsLinks-module--ar-quote--18X4g";
export var arQuoteMobile = "ArAboutNewsLinks-module--ar-quote-mobile--3kh9T";
export var arGradientLight = "ArAboutNewsLinks-module--ar-gradient-light--18lYt";
export var arGradientBlue = "ArAboutNewsLinks-module--ar-gradient-blue--35AWU";
export var arTransition = "ArAboutNewsLinks-module--ar-transition--35hko";
export var container = "ArAboutNewsLinks-module--container--gsrOc";
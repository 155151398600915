// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArAboutHero-module--ar-h1-mobile--36qrD";
export var arH2Mobile = "ArAboutHero-module--ar-h2-mobile--3K_ou";
export var arH3Mobile = "ArAboutHero-module--ar-h3-mobile--3PWGD";
export var arH4Mobile = "ArAboutHero-module--ar-h4-mobile--3F-kj";
export var arH5Mobile = "ArAboutHero-module--ar-h5-mobile--V7F3o";
export var arH1 = "ArAboutHero-module--ar-h1--3XQsD";
export var arH2 = "ArAboutHero-module--ar-h2--1LuNL";
export var arH3 = "ArAboutHero-module--ar-h3--3coWq";
export var arH4 = "ArAboutHero-module--ar-h4--l6t1r";
export var arH5 = "ArAboutHero-module--ar-h5--1JJxU";
export var arParagraphXl = "ArAboutHero-module--ar-paragraph-xl--m3rjc";
export var arParagraphXlMedium = "ArAboutHero-module--ar-paragraph-xl-medium--286it";
export var arParagraphBase = "ArAboutHero-module--ar-paragraph-base--1ESiW";
export var arParagraphBaseMedium = "ArAboutHero-module--ar-paragraph-base-medium--zC0nN";
export var arParagraphSmall = "ArAboutHero-module--ar-paragraph-small--2cbfj";
export var arParagraphSmallMedium = "ArAboutHero-module--ar-paragraph-small-medium--3nzCc";
export var arParagraphXsmall = "ArAboutHero-module--ar-paragraph-xsmall--38WFk";
export var arParagraphXsmallMedium = "ArAboutHero-module--ar-paragraph-xsmall-medium--2w2DR";
export var arLinkMobile = "ArAboutHero-module--ar-link-mobile--qui8S";
export var arLinkDesktop = "ArAboutHero-module--ar-link-desktop--U9mOE";
export var arQuote = "ArAboutHero-module--ar-quote--1DRSj";
export var arQuoteMobile = "ArAboutHero-module--ar-quote-mobile--3_E7t";
export var arGradientLight = "ArAboutHero-module--ar-gradient-light--39MQB";
export var arGradientBlue = "ArAboutHero-module--ar-gradient-blue--hvDiy";
export var arTransition = "ArAboutHero-module--ar-transition--1zuZO";
export var container = "ArAboutHero-module--container--2i75a";
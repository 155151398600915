// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArAboutOurTeam-module--ar-h1-mobile--36wXQ";
export var arH2Mobile = "ArAboutOurTeam-module--ar-h2-mobile--FpQT8";
export var arH3Mobile = "ArAboutOurTeam-module--ar-h3-mobile--2Myx1";
export var arH4Mobile = "ArAboutOurTeam-module--ar-h4-mobile--6mwIn";
export var arH5Mobile = "ArAboutOurTeam-module--ar-h5-mobile--1xWo_";
export var arH1 = "ArAboutOurTeam-module--ar-h1--1rn34";
export var arH2 = "ArAboutOurTeam-module--ar-h2--1jDJ-";
export var arH3 = "ArAboutOurTeam-module--ar-h3--13Ih5";
export var arH4 = "ArAboutOurTeam-module--ar-h4--3WToj";
export var arH5 = "ArAboutOurTeam-module--ar-h5--A_7Ra";
export var arParagraphXl = "ArAboutOurTeam-module--ar-paragraph-xl--15QlC";
export var arParagraphXlMedium = "ArAboutOurTeam-module--ar-paragraph-xl-medium--21-wI";
export var arParagraphBase = "ArAboutOurTeam-module--ar-paragraph-base--32lup";
export var arParagraphBaseMedium = "ArAboutOurTeam-module--ar-paragraph-base-medium--DkVQ9";
export var arParagraphSmall = "ArAboutOurTeam-module--ar-paragraph-small--OE3Fk";
export var arParagraphSmallMedium = "ArAboutOurTeam-module--ar-paragraph-small-medium--1wWLt";
export var arParagraphXsmall = "ArAboutOurTeam-module--ar-paragraph-xsmall--2u5j0";
export var arParagraphXsmallMedium = "ArAboutOurTeam-module--ar-paragraph-xsmall-medium--1TcUw";
export var arLinkMobile = "ArAboutOurTeam-module--ar-link-mobile--2SpTm";
export var arLinkDesktop = "ArAboutOurTeam-module--ar-link-desktop--PRQYx";
export var arQuote = "ArAboutOurTeam-module--ar-quote--2GQKQ";
export var arQuoteMobile = "ArAboutOurTeam-module--ar-quote-mobile--21TfZ";
export var arGradientLight = "ArAboutOurTeam-module--ar-gradient-light---_F53";
export var arGradientBlue = "ArAboutOurTeam-module--ar-gradient-blue--usGuy";
export var arTransition = "ArAboutOurTeam-module--ar-transition--3pQgY";
export var container = "ArAboutOurTeam-module--container--3VWRG";
export var team = "ArAboutOurTeam-module--team--Nh5jo";
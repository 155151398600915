// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArAboutLogos-module--ar-h1-mobile--35mFb";
export var arH2Mobile = "ArAboutLogos-module--ar-h2-mobile--2O-ma";
export var arH3Mobile = "ArAboutLogos-module--ar-h3-mobile--3kcS8";
export var arH4Mobile = "ArAboutLogos-module--ar-h4-mobile--2qP7k";
export var arH5Mobile = "ArAboutLogos-module--ar-h5-mobile--2L8n4";
export var arH1 = "ArAboutLogos-module--ar-h1--2ZnXZ";
export var arH2 = "ArAboutLogos-module--ar-h2--3SiQR";
export var arH3 = "ArAboutLogos-module--ar-h3--2SUHC";
export var arH4 = "ArAboutLogos-module--ar-h4--2af2L";
export var arH5 = "ArAboutLogos-module--ar-h5--1wfXb";
export var arParagraphXl = "ArAboutLogos-module--ar-paragraph-xl--WumEe";
export var arParagraphXlMedium = "ArAboutLogos-module--ar-paragraph-xl-medium--2lAJB";
export var arParagraphBase = "ArAboutLogos-module--ar-paragraph-base--2bO18";
export var arParagraphBaseMedium = "ArAboutLogos-module--ar-paragraph-base-medium--3u-hC";
export var arParagraphSmall = "ArAboutLogos-module--ar-paragraph-small--2HcBr";
export var arParagraphSmallMedium = "ArAboutLogos-module--ar-paragraph-small-medium--szyMu";
export var arParagraphXsmall = "ArAboutLogos-module--ar-paragraph-xsmall--UjkW_";
export var arParagraphXsmallMedium = "ArAboutLogos-module--ar-paragraph-xsmall-medium--2_DT0";
export var arLinkMobile = "ArAboutLogos-module--ar-link-mobile--212W1";
export var arLinkDesktop = "ArAboutLogos-module--ar-link-desktop--3ZeZC";
export var arQuote = "ArAboutLogos-module--ar-quote--HDVe3";
export var arQuoteMobile = "ArAboutLogos-module--ar-quote-mobile--3MPar";
export var arGradientLight = "ArAboutLogos-module--ar-gradient-light--3xSqm";
export var arGradientBlue = "ArAboutLogos-module--ar-gradient-blue--899vL";
export var arTransition = "ArAboutLogos-module--ar-transition--2bLVx";
export var container = "ArAboutLogos-module--container--3z1oR";